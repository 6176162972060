/*******************************
     User Global Variables
*******************************/
@fontName          : 'Inter';

/*******************************
     Colors
*******************************/
@primaryColor : #0084A0;
@primaryActiveColor : #0084A020;
@primaryActiveColorHover : #0084A08;
@secondaryColor : #364657;
@grey : #586879;
@grey1 : #1C2630;
@grey5: #CFD7DF;
@grey6 : #E6EBF1;
@white : #ffffff
@yellow : #EEDDB0;
@yellow1 : #D4A93A; 
@green : #00841D;
@red : #D62424;
@teal : #0084A0;
@checkGrey : #AAB7C4;
@content-background: #F1F4F7;
@loading-content-background: #F1F4F7;
@mobileDashboardGrey: #E6EBF1;
@chevron-down: #1C2630;
@profile-card-background: #F7F8FA;
@loginBackground: #3c648f;
@navBarBackground: #FFFFFF;
@navbarItemColor: #1C2630;
@navbarActiveItemColor: #0E96C7;
@disabledOpacity: 0.6;